require("bootstrap");
import bsCustomFileInput from 'bs-custom-file-input';


$(document).ready(() => {
    bsCustomFileInput.init();
    
    $('[data-toggle="tooltip"]').tooltip({
        offset : '0 15'
    });

    $('body').on('click', '.hide-link-item', function (e) {
        e.preventDefault();
        if (!confirm($(this).data('message'))) {
            return
        }

        $.get(gtmarketplaceAjaxUrl, {
            ajax: true,
            action: 'hideMenu',
            idCustomer: $(this).data('idCustomer')
        })
            .done(function (response) {
                if (response == 'OK') {
                    $('#gtmarketplace-link').remove();
                } else {
                    console.error(response);
                }
            })
            .fail(function (response) {
                console.error(response);
            });
    });

    if (window.location.href.indexOf('isNewProduct') != -1) {
        $('body').on('keyup', '#show_product #name', function (e) {
            e.preventDefault();
            let value = e.target.value.toLowerCase();
            value = value.replace(/ +/g, '-');
            $('#link_rewrite').val(value);
        });
    }

    $('body').on('click', '.delete', function (e) {
        if ($(e.target).hasClass('delete')) {
            elt = $(e.target)
        } else {
            elt = $(e.target).parent()
        }

        if (confirm(elt.data('message'))) {
            window.location.replace(elt.data('target'));
        }
    })

    // it's free
    $('body').on('click', '#is_free0', function (e) {
        $.get(gtmarketplaceCarrierToggleIsFreeUrl)
            .done(function () {
                location.reload()
            })
            .fail(function (response) {
                console.error(response);
            });
    })

    // it's not free
    $('body').on('click', '#is_free1', function (e) {
        $.get(gtmarketplaceCarrierToggleIsFreeUrl)
            .done(function () {
                location.reload()
            })
            .fail(function (response) {
                console.error(response);
            });
    })

    $('body').on('click', '#reassign_carriers', function (e) {
        let target = e.target.dataset.target;
        let message1 = e.target.dataset['message-1'];
        let message2 = e.target.dataset['message-2'];

        if (!target) {
            target = e.target.parentNode.dataset.target;
            message1 = e.target.parentNode.dataset['message-1'];
            message2 = e.target.parentNode.dataset['message-2'];
        }

        if (confirm(message1 + "\n\n" + message2)) {
            $.get(target);
        }
    });
    $('body').on('click', '.send-invitation-btn', function (e) {
        e.preventDefault();
        let target = document.getElementById(e.target.dataset.key).querySelector('.email_input').value;
        let id_supplier = document.getElementById(e.target.dataset.key).querySelector('.id_supplier').value;
        let role_type = document.getElementById(e.target.dataset.key).querySelector('.role_type').value;

        if (emailIsValid(target)) {
            //Call sendMail
            $.ajax({
                url: mailControllerUrl,
                jsonp: "callback",
                Type: "POST",
                data: {
                    ajax: 1,
                    action: 'addUser',
                    target: target,
                    id_supplier: id_supplier,
                    role_type: role_type
                }
            }).done(() => {
                document.location.href = invitationController;
            });
        } else {
            displayErrorMessage('Email Invalid !');
        }

    });

    $('body').on('click', '.delete-contact-btn', function (e) {
        e.preventDefault();
        let id_customer = e.target.dataset.key;
        $.ajax({
            url: typecontactControllerUrl,
            jsonp: "callback",
            Type: "POST",
            data: {
                ajax: 1,
                action: 'deleteContact',
                id_customer: id_customer
            }
        }).done(() => {
            document.location.href = invitationController;
        });

    });

    $('body').on('click', '.delete-invitation-btn', function (e) {
        e.preventDefault();

        let email = e.target.dataset.key;
        $.ajax({
            url: invitationController,
            jsonp: "callback",
            Type: "POST",
            data: {
                ajax: 1,
                action: 'deleteInvitation',
                email: email
            }
        }).done(() => {
            document.location.href = invitationController;
        });

    });

    /** Supplier ContactForm */
    const supplierContactForm = $(".supplier_contact_form");
    if(supplierContactForm) {
        supplierContactForm.clone().appendTo('#center_column').show();
        supplierContactForm.remove();
    }

    function emailIsValid(email) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }

    function displayErrorMessage(message) {
        var alert_block = $('#ajax_confirmation');

        $('.alert-text').find("p").text(message);
        alert_block.addClass("alert-danger").slideDown(300, function () {
            alert_block.find('.close').on('click', function (e) {
                e.preventDefault();
                alert_block.removeClass('alert-danger');
                document.location.href = typecontactControllerUrl;
            });
        });
    }

    let url = document.location.toString();
    if (url.match('#')) {
        $('.nav-tabs a[href="#' + url.split('#')[1] + '"]').tab('show');
    }

    /** Trumbowyg */
    $('textarea.trumbowyg[name!="meta_description"]').trumbowyg({
        tagsToRemove: ['script'],
        tagsToKeep: ['p', 'a', 'img'],
        imageWidthModalEdit: true,
    });

    /** Dashboard */
    var Chart = require('chart.js');
    Chart.defaults.global.legend.position = "bottom";
    Chart.defaults.global.legend.align = "start";
    Chart.defaults.global.animation.animateRotate = true;

    function createChart(element, data, labels) {
        const bgColors = [
            'rgba(255, 99, 132)',
            'rgba(54, 162, 235)',
            'rgba(255, 206, 86)',
            'rgba(75, 192, 192)',
            'rgba(153, 102, 255)',
            'rgba(255, 159, 64)',
            'rgba(31, 52, 255)',
            'rgba(100, 200, 50)',
            'rgba(153, 202, 155)',
        ];

        return new Chart(element, {
            type: 'doughnut',
            data: {
                labels: labels,
                datasets: [{
                    data: data,
                    backgroundColor: bgColors,
                }],
            },
        });
    }

    $('.chart').each(function (index, element) {
        element = $(element);
        let values = element.data('values');
        let labels = element.data('labels');

        if (!values || !labels) {
            return;
        }

        createChart(
            element,
            typeof values === 'string' ? values.split(",") : toString(values).split(","),
            typeof labels === 'string' ? labels.split(",") : toString(labels).split(",")
        )
    });

    /** Import form */
    $('#flux-proc-field').css('display', 'none');
    $('.process-selector').show();
    $('body').on('click', '#proc-file-btn, #proc-flux-btn', (e) => {
        e.preventDefault();
        $('#proc-file-btn, #proc-flux-btn').toggleClass('btn-secondary').toggleClass('btn-outline-secondary');
        $('#flux-proc-field, #file-proc-field').toggle();
    });

    /** Schedule form */
    const fluxFields = 'input#flux_url';
    const ftpFields = 'input#ftp_host, input#ftp_login, input#ftp_password, input#ftp_path, input#ftp_filename, input#ftp_port';

    toggleScheduleImportFields();
    $('body').on('change', "input[name='import_process']", (e) => {
        toggleScheduleImportFields();
    });

    function toggleScheduleImportFields() {

        const ftpRadio = document.getElementById('import_process0');
        const fluxRadio = document.getElementById('import_process1');

        if (!ftpRadio) {
            return;
        }

        if (ftpRadio.checked) {
            $(fluxFields).parents('.form-group').slideUp('', () => {
                $(ftpFields).parents('.form-group').slideDown();
            });
        } else if (fluxRadio.checked) {
            $(ftpFields).parents('.form-group').slideUp('', () => {
                $(fluxFields).parents('.form-group').slideDown();
            });
        }
    }
});